import // Action Types goes here

'../actionTypes';

const initialState = {
	// State goes here
};

const reducersTemplate = (state = initialState, action) => {
	switch (action.type) {
	// case ACTION_TYPES:
	//   return {...state, stateSomething: action.data};
	default:
		return state;
	}
};

export default reducersTemplate;
