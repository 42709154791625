import axios from 'axios';

// Global components
import BaseComponent from 'components/BaseComponent';

// Assets
import PNG_10PcsKcw from 'assets/images/prize_10pcs_kcw.png';
import PNG_2pcsMfcRiceSet from 'assets/images/prize_2pcs_mfc_rice_set.png';
import PNG_1pcsMfcRiceSet from 'assets/images/prize_1pcs_mfc_rice_set.png';
import PNG_MeteorChickenRice from 'assets/images/prize_meteor_chicken_rice.png';
import PNG_MoonchickenGridBackground from 'assets/images/background_grid_moonchicken.png';
import PNG_MoonchickenLogo from 'assets/images/logo_moonchicken.png';
import SVGTrianglePointer from 'assets/illustrations/triangle-pointer-moonchicken.svg';

import styles from './moonchicken.module.scss';

const renderLabel = (label, image) => {
	return (
		<span className={styles.Moonchicken__pieSliceLabelContainer}>
			<img
				src={image}
				alt="prize-image" />
			<p>{label}</p>
		</span>
	);
};

const data = [
	{
		labelId: ['Rp1 1pcs MFC Rice set'],
		label: renderLabel('1pcs Moon Fried Chicken Rice Set Rp1', PNG_1pcsMfcRiceSet),
		labelString: '1pcs Moon Fried Chicken Rice Set Rp1',
	},
	{
		labelId: ['Rp1 10pcs KCW'],
		label: renderLabel('10pcs Korean Chicken Wings Rp1', PNG_10PcsKcw),
		labelString: '10pcs Korean Chicken Wings Rp1',
	},
	{
		labelId: ['Rp1 2pcs MFC Rice Set'],
		label: renderLabel('2pcs Moon Fried Chicken Rice Set Rp1', PNG_2pcsMfcRiceSet),
		labelString: '2pcs Moon Fried Chicken Rice Set Rp1',
	},
	{
		labelId: ['Rp1 Meteor Chicken Rice'],
		label: renderLabel('Meteor Chicken Rice Rp1', PNG_MeteorChickenRice),
		labelString: 'Meteor Chicken Rice Rp1',
	},
];

const Moonchicken = () => {
	// Event handler methods
	const onClickStart = async () => {
		return axios({
			url: 'https://asia-southeast1-backend-services-258111.cloudfunctions.net/try-your-luck?campaign=mc_jabo',
			method: 'get',
		});
	};
	// Render
	return (
		<>
			<div
				className={styles.Moonchicken__background}
				style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/background_moonchicken.jpg'})`}}>
				<img
					alt="grid"
					src={PNG_MoonchickenGridBackground} />
			</div>
			<BaseComponent
				data={data}
				oddColor="#500D94"
				evenColor="#6419B1"
				onClickStart={onClickStart}
				cookieKey="prize_moonchicken"
				pointerSvg={SVGTrianglePointer}
				imageLogo={PNG_MoonchickenLogo}
				buttonClassName={styles.Moonchicken__button}
				footerClassName={styles.Moonchicken__footer}
				imageLogoClassName={styles.Moonchicken__imageLogo}
				wheelClassName={styles.Moonchicken__wheelContainer}
				disableTextClassName={styles.Moonchicken__disableText} />
		</>
	);
};

export default Moonchicken;
