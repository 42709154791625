import axios from 'axios';

// Global components
import BaseComponent from 'components/BaseComponent';

// Global utils
import {wait} from 'utils/index';

// Assets
import PNG_NasiKulit from 'assets/images/prize_nasi_kulit.png';
import PNG_GeprekNasiWangiSambalMatah from 'assets/images/prize_geprek_nasi_wangi_sambal_matah.png';
import PNG_GeprekNasiWangiSambalOri from 'assets/images/prize_geprek_nasi_wangi_sambal_ori.png';
import PNG_GeprekSambalOriMozza from 'assets/images/prize_geprek_sambal_ori_mozza.png';
import PNG_AyamKoploLogo from 'assets/images/logo_ayam_koplo.png';
import SVGTrianglePointer from 'assets/illustrations/triangle-pointer-koplo.svg';

import styles from './koplo.module.scss';

const renderLabel = (label, image) => {
	return (
		<span className={styles.AyamKoplo__pieSliceLabelContainer}>
			<img
				src={image}
				alt="prize-image" />
			<p>{label}</p>
		</span>
	);
};

const data = [
	{
		labelId: ['Rp1 Geprek Nasi Wangi Sambal Matah Ori Reg'],
		label: renderLabel('Nasi Wangi Geprek Matah Rp1', PNG_GeprekNasiWangiSambalMatah),
		labelString: 'Nasi Wangi Geprek Matah Rp1',
	},
	{
		labelId: ['Rp1 Nasi Wangi Sambal Ori Reg'],
		label: renderLabel('Nasi Wangi Sambal Ori Rp1', PNG_GeprekNasiWangiSambalOri),
		labelString: 'Nasi Wangi Sambal Ori Rp1',
	},
	{
		labelId: ['Rp1 Nasi Kulit Renyah Idolaku'],
		label: renderLabel('Nasi Kulit Renyah Idolaku Rp1', PNG_NasiKulit),
		labelString: 'Nasi Kulit Renyah Idolaku Rp1',
	},
	{
		labelId: ['Rp1 Geprek Sambal Ori Mozza'],
		label: renderLabel('Geprek Sambal Ori Mozza Rp100', PNG_GeprekSambalOriMozza),
		labelString: 'Geprek Sambal Ori Mozza Rp1',
	},
];

const AyamKoplo = () => {
	// Event handler methods
	const onClickStart = async () => {
		// const response = {
		// 	data: {
		// 		voucher: 'Rp1 Nasi Wangi Sambal Ori Reg',
		// 		voucher_code: 'FTSFU5F4D',
		// 	},
		// };
		// await wait(2575);
		// return response;
		return axios({
			url: 'https://asia-southeast1-backend-services-258111.cloudfunctions.net/try-your-luck?campaign=ak_jabo',
			method: 'get',
		});
	};
	// Render
	return (
		<>
			<div
				className={styles.AyamKoplo__background}
				style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/background_ayam_koplo.jpg'})`}} />
			<BaseComponent
				data={data}
				oddColor="#C01B62"
				evenColor="#E4327F"
				cookieKey="prize_ayamkoplo"
				onClickStart={onClickStart}
				imageLogo={PNG_AyamKoploLogo}
				pointerSvg={SVGTrianglePointer}
				buttonClassName={styles.AyamKoplo__button}
				footerClassName={styles.AyamKoplo__footer}
				imageLogoClassName={styles.AyamKoplo__imageLogo}
				wheelClassName={styles.AyamKoplo__wheelContainer}
				disableTextClassName={styles.AyamKoplo__disableText} />
		</>
	);
};

export default AyamKoplo;
