import {forwardRef, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Assets
import SVGTrianglePointer from 'assets/illustrations/triangle-pointer.svg';

// Local styles
import styles from './pieWheel.module.scss';

const PieWheel = forwardRef((props, ref) => {
	const bodyStyle = getComputedStyle(document.body);
	const oddColor = props.oddColor || bodyStyle.getPropertyValue('--primaryDefault');
	const evenColor = props.evenColor || bodyStyle.getPropertyValue('--primaryHover');
	const percentage = 100 / props.data.length;
	const innerRotate = (360 * (percentage / 2)) / 100;
	const isSpinning = useRef(false);
	const wheelRef = useRef(null);
	const initialSpin = 360 * 3;
	// GSAP
	const tweensTimeline = useRef(null);
	const repeatTween = useRef(null);
	// Methods
	const setRef = () => {
		const refObject = {
			spinWheel,
			stopWheelIndex,
		};
		if (ref && ref.hasOwnProperty('current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	const stopWheelIndex = (index = 0) => {
		const additionalSpin = innerRotate + ((360 * percentage) / 100 * index);
		const endTimeline = gsap.fromTo(wheelRef.current, {
			rotation: 0,
		}, {
			rotation: initialSpin - additionalSpin,
			ease: 'power2.out',
			duration: 2,
			onComplete: () => {
				props.onComplete();
				isSpinning.current = false;
			},
		});
		tweensTimeline.current.add(endTimeline);
		repeatTween.current.repeat(1);
	};
	const spinWheel = index => {
		const isIndexValid = index === undefined ? true : index <= props.data.length - 1;
		if (!isSpinning.current && isIndexValid) {
			isSpinning.current = true;
			tweensTimeline.current = gsap.timeline()
				.to(wheelRef.current, {
					onStart: props.onStart,
					rotation: initialSpin,
					ease: 'power2.in',
					duration: 2,
				});
			repeatTween.current = gsap.fromTo(wheelRef.current, {
				rotation: 0,
			}, {
				rotation: initialSpin,
				ease: 'linear',
				duration: 1,
				repeat: -1,
			});
			tweensTimeline.current.add(repeatTween.current);
		}
	};
	// Hooks
	useEffect(() => setRef(), []);
	// Render
	return (
		<div className={clsx(props.className, styles.PieWheel)}>
			<img
				alt="triangle-pointer"
				src={props.pointerSvg}
				className={styles.PieWheel__pointer} />
			{props.children}
			<div
				ref={wheelRef}
				className={clsx(props.wheelClassName, styles.PieWheel__container)}>
				{props.data.map((data, index) => {
					const backgroundColor = data.sliceBackgroundColor || (index % 2 ? oddColor : evenColor);
					const outerRotate = innerRotate + ((360 * percentage) / 100 * index);
					return (
						<span
							key={index}
							className={styles.PieWheel__slice}
							style={{transform: `rotateZ(${outerRotate}deg)`}}>
							<div className={styles.PieWheel__slice__contentContainer}>
								{data?.label ? data.label : (
									<p className={styles.PieWheel__slice__circle}>{index + 1}</p>
								)}
							</div>
							<span
								className={styles.PieWheel__slice__inner}
								style={{
									transform: `rotateZ(-${innerRotate}deg)`,
									background: `conic-gradient(${backgroundColor} 0.00% ${percentage}%, transparent ${percentage}%)`,
								}} />
						</span>
					);
				})}
			</div>
		</div>
	);
});

PieWheel.defaultProps = {
	onStart: () => null,
	onComplete: () => null,
	pointerSvg: SVGTrianglePointer,
	data: new Array(8).fill(0),
};

PieWheel.propTypes = {
	data: PropTypes.array,
	children: PropTypes.any,
	onStart: PropTypes.func,
	pointerSvg: PropTypes.any,
	onComplete: PropTypes.func,
	oddColor: PropTypes.string,
	evenColor: PropTypes.string,
	className: PropTypes.string,
	wheelClassName: PropTypes.string,
};

export default PieWheel;
