import axios from 'axios';
import clsx from 'clsx';

// Global components
import BaseComponent from 'components/BaseComponent';

// Local styles
import styles from './home.module.scss';
// import {wait} from 'utils/index';

const renderLabel = (data = {}, config = {
	color: 'red',
	mainLabelSize: 'small',
}) => {
	const getLabelColor = () => {
		switch (config?.color) {
		case 'white':
			return styles.PieSliceLabelContainer__labelColorWhite;
		case 'yellow':
			return styles.PieSliceLabelContainer__labelColorYellow;
		case 'red':
		default:
			return styles.PieSliceLabelContainer__labelColorRed;
		}
	};
	const getMainLabelSize = () => {
		switch (config?.mainLabelSize) {
		case 'medium':
			return styles.PieSliceLabelContainer__labelMedium;
		case 'small':
		default:
			return styles.PieSliceLabelContainer__labelSmall;
		}
	};
	return (
		<div className={styles.PieSliceLabelContainer}>
			<p className={clsx(getMainLabelSize(), getLabelColor())}>
				{data?.label}
			</p>
			{data?.value && (
				<p className={clsx(
					styles.PieSliceLabelContainer__labelMedium,
					getLabelColor(),
				)}>
					{data?.value}
				</p>
			)}
		</div>
	);
};

const data = [
	{
		labelId: ['Potongan 100% Rp100.000 - All Menu', 'Voucher Rp. 100 ribu'],
		label: renderLabel({label: 'Voucher', value: '100.000'}),
		sliceBackgroundColor: '#FAB300',
	},
	{
		labelId: ['Potongan 100% Rp25.000 - All Menu', 'Voucher Rp. 25 ribu'],
		label: renderLabel({label: 'Voucher', value: '25.000'}, {
			color: 'white',
		}),
	},
	{
		labelId: ['Potongan 100% Rp50.000 - All Menu', 'Voucher Rp. 50 ribu'],
		label: renderLabel({label: 'Voucher', value: '50.000'}, {
			color: 'white',
		}),
	},
	{
		labelId: ['Potongan 100% Rp75.000 - All Menu', 'Voucher Rp. 75 ribu'],
		label: renderLabel({label: 'Voucher', value: '75.000'}, {
			color: 'white',
		}),
	},
	{
		labelId: ['FREE Aburi Gyudon', 'FREE San Gyu Aburi Gyudon'],
		label: renderLabel({label: 'Aburi', value: 'Rp100'}, {
			color: 'yellow',
		}),
	},
	{
		labelId: ['FREE Favorite Onigiri Bento A', 'FREE San Gyu Favorite Onigiri Bento A'],
		label: renderLabel({label: 'Bento', value: 'Rp100'}, {
			color: 'yellow',
		}),
	},
	{
		labelId: ['FREE Tori Steak Teriyaki', 'FREE San Gyu Tori Steak Teriyaki'],
		label: renderLabel({label: 'Tori Steak', value: 'Rp100'}, {
			color: 'yellow',
		}),
	},
	{
		labelId: ['FREE Merchandise'],
		label: renderLabel({label: 'Merch'}, {
			mainLabelSize: 'medium',
			color: 'white',
		}),
	},
];

const Home = () => {
	// Event handler methods
	const onClickStart = async () => {
		return axios({
			url: 'https://asia-southeast1-backend-services-258111.cloudfunctions.net/try-your-luck',
			method: 'get',
		});
		// const response = {
		// 	data: {
		// 		voucher: 'FREE San Gyu Favorite Onigiri Bento A',
		// 		voucher_code: 'FTSFU5F4D',
		// 	},
		// };
		// await wait(2575);
		// return response;
	};
	// Render
	return (
		<BaseComponent
			data={data}
			onClickStart={onClickStart} />
	);
};

export default Home;
