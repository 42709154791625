import ReactDOM from 'react-dom';
import {useState, forwardRef, useEffect, useRef} from 'react';
import {Icon, iconSolid, Button} from '@hangry/react-ui-kit';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Assets
import imageSurprise from 'assets/images/illustration.png';

// Local styles
import styles from './resultModal.module.scss';

const cacheImage = new Image();
cacheImage.src = imageSurprise;

const ResultModal = props => {
	const backgroundRef = useRef(null);
	const contentRef = useRef(null);
	// Methods
	const toggleModalVisibility = (state = true, onComplete = () => null) => {
		gsap.to([backgroundRef.current, contentRef.current], {
			opacity: state ? 1 : 0,
			duration: 0.3,
			onComplete,
		});
	};
	const closeModal = () => {
		if (props.enableBackgroundClose) {
			toggleModalVisibility(false, props.closeModal);
		}
	};
	// Hooks
	useEffect(() => toggleModalVisibility(true), []);
	// Render
	return (
		<div className={styles.ResultModal}>
			<span
				ref={backgroundRef}
				onClick={closeModal}
				className={styles.ResultModal__background} />
			<div
				ref={contentRef}
				className={styles.ResultModal__container}>
				<img
					alt="Illustration"
					src={cacheImage.src}
					className={styles.ResultModal__container__surpriseIllustration} />
				<div className={styles.ResultModal__container__card}>
					{props.type === 'voucher' ? (
						<>
							{/* LABEL */}
							<div className={clsx(
								styles.ResultModal__container__labelContainer,
								styles.ResultModal__container__headTail,
							)}>
								<p className={styles.ResultModal__container__labelContainer__title}>
									Selamat, kamu dapat voucher Hangry
								</p>
								<p className={styles.ResultModal__container__labelContainer__content}>
									{props.prizeLabel}
								</p>
							</div>
							{/* SEPARATOR */}
							<div className={styles.ResultModal__container__separator}>
								<span className={styles.ResultModal__container__separator__transparentCurve} />
								<span className={styles.ResultModal__container__separator__dashContainer}>
									<hr />
								</span>
								<span className={styles.ResultModal__container__separator__transparentCurve} />
							</div>
							{/* VOUCHER CODE */}
							<div className={styles.ResultModal__container__voucherCode}>
								<p className={styles.ResultModal__container__voucherCode__title}>Kode Voucher</p>
								<div className={styles.ResultModal__container__voucherCode__contentContainer}>
									<button onClick={() => navigator.clipboard.writeText(props.prizeContent)}>
										<Icon icon={iconSolid.faCopy} />
									</button>
									<p>{props.prizeContent}</p>
								</div>
							</div>
							{/* SEPARATOR */}
							<div className={styles.ResultModal__container__separator}>
								<span className={styles.ResultModal__container__separator__transparentCurve} />
								<span className={styles.ResultModal__container__separator__dashContainer}>
									<hr />
								</span>
								<span className={styles.ResultModal__container__separator__transparentCurve} />
							</div>
							{/* DIRECTION */}
							<div className={clsx(
								styles.ResultModal__container__instruction,
								styles.ResultModal__container__headTail,
							)}>
								<div className={styles.ResultModal__container__instruction__inner}>
									<hr />
									<div>
										<span>1</span>
										<p>Segera screenshot halaman ini agar kamu tidak lupa kodenya 📸</p>
									</div>
									<div>
										<span>2</span>
										<p>Download Hangry App lalu masukkan kode voucher yang tertera</p>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className={clsx(
							styles.ResultModal__container__labelContainer,
							styles.ResultModal__container__singleTicket,
							styles.ResultModal__container__headTail,
						)}>
							<p className={styles.ResultModal__container__labelContainer__title}>
								Selamat, kamu dapat
							</p>
							<p className={styles.ResultModal__container__labelContainer__content}>
								{props.prizeLabel}
							</p>
						</div>
					)}
				</div>
				{props.type === 'voucher' && (
					<Button
						size="large"
						scaled={true}
						label="Download Hangry App"
						className={styles.ResultModal__container__downloadAppButton}
						onClick={() => window.open('https://hangry.page.link/EQfo', '_blank')} />
				)}
			</div>
		</div>
	);
};

ResultModal.propTypes = {
	closeModal: PropTypes.func,
	prizeLabel: PropTypes.string,
	prizeContent: PropTypes.string,
	enableBackgroundClose: PropTypes.bool,
	type: PropTypes.oneOf(['voucher', 'merch']),
};

const ReactPortalWrapper = forwardRef((props, ref) => {
	const [modalShown, setModalState] = useState(false);
	// Methods
	const setRef = () => {
		const refObject = {
			show: () => setModalState(true),
			hide: () => setModalState(false),
		};
		if (ref && ref.hasOwnProperty('current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	// Hooks
	useEffect(() => setRef(), []);
	// Render
	return ReactDOM.createPortal(
		modalShown ? (
			<ResultModal
				{...props}
				closeModal={() => setModalState(false)} />
		) : null,
		document.body,
	);
});

ReactPortalWrapper.defaultProps = {
	type: 'voucher',
	enableBackgroundClose: true,
};

ReactPortalWrapper.propTypes = {
	prizeLabel: PropTypes.string,
	prizeContent: PropTypes.string,
	enableBackgroundClose: PropTypes.bool,
	type: PropTypes.oneOf(['voucher', 'merch']),
};

export default ReactPortalWrapper;
