import React from 'react';
import {
	Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';

// Screens
import Home from 'views/Home';
import Koplo from 'views/Koplo';
import Moonchicken from 'views/Moonchicken';

import history from 'utils/history';

// Global styles
import 'styles/app.scss';

export default function App() {
	return (
		<Router history={history}>
			<Switch>
				{/* Available path */}
				<Route
					exact
					path="/">
					<Home />
				</Route>
				<Route
					exact
					path="/moonchicken">
					<Moonchicken />
				</Route>
				<Route
					exact
					path="/ayamkoplo">
					<Koplo />
				</Route>
				{/* Invalid path (redirect to home) */}
				<Redirect to="/" />
			</Switch>
		</Router>
	);
}
