import React from 'react';
import ReactDOM from 'react-dom';
import {FpjsProvider} from '@fingerprintjs/fingerprintjs-pro-react';
import {Provider} from 'react-redux';
import Bugsnag from '@bugsnag/js';

import App from './App';

import crashLogger from 'utils/logger';

import store from 'store';

import reportWebVitals from './reportWebVitals';

import './index.scss';

const ErrorBoundary = crashLogger.bugsnagEnabled
	? Bugsnag.getPlugin('react').createErrorBoundary(React)
	: React.Fragment;

ReactDOM.render(
	<React.StrictMode>
		<FpjsProvider
			loadOptions={{
				apiKey: 'RADdTC3NQRhSA1zJ6j5x',
				region: 'ap',
			}}>
			<ErrorBoundary>
				<Provider store={store}>
					<App />
				</Provider>
			</ErrorBoundary>
		</FpjsProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
