import {useRef, useState, useEffect} from 'react';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';
import {isMobile} from 'react-device-detect';
import {Button} from '@hangry/react-ui-kit';
import LocalStorage from 'local-storage';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import clsx from 'clsx';

// Global components
import PieWheel from 'components/PieWheel';
import ResultModal from 'components/ResultModal';

// Assets
import SVGHangryIcon from 'assets/illustrations/hangry-icon.svg';
import SVGLogo from 'assets/illustrations/home-logo.svg';

// Local styles
import styles from './baseComponent.module.scss';

const BaseComponent = props => {
	const fingerprint = useVisitorData({extendedResult: true});
	const [isBrowserIncognito, setBrowserIncognitoState] = useState(null);
	const [startButtonHidden, setButtonHidden] = useState(false);
	const [prize, setPrize] = useState({});
	const resultModalRef = useRef(null);
	const pieWheelRef = useRef(null);
	const prizeRef = useRef({});
	// Methods
	const setPrizeStorage = (key, value) => {
		LocalStorage.set(key, value);
		Cookies.set(key, value);
	};
	const getPrizeStorage = key => {
		const localStoragePrize = LocalStorage.get(key);
		const cookiePrize = Cookies.get(key);
		return localStoragePrize ? localStoragePrize : cookiePrize ? cookiePrize : localStoragePrize;
	};
	// Event handler methods
	const onClickStartButton = async () => {
		if (isEmpty(getPrizeStorage(props.cookieKey)) && typeof props.onClickStart === 'function') {
			try {
				pieWheelRef.current.spinWheel();
				const response = await props.onClickStart();
				const prizeData = props.data.find(data => data.labelId.includes(response.data.voucher));
				const prizeObject = {
					prizeContent: response.data.voucher_code,
					prizeLabel: prizeData?.labelString || response.data.voucher,
					type: response.data.voucher === 'FREE Merchandise' ? 'merch' : 'voucher',
				};
				prizeRef.current = prizeObject;
				setPrize(prizeObject);
				const findIndex = props.data.findIndex(element => element.labelId.includes(response.data.voucher));
				pieWheelRef.current.stopWheelIndex(findIndex);
			} catch (e) {
				console.log(e);
			}
		}
	};
	const onCompleteSpinner = () => {
		if (process.env.NODE_ENV !== 'development') {
			setPrizeStorage(props.cookieKey, JSON.stringify(prizeRef.current));
		}
		setTimeout(() => {
			setButtonHidden(false);
			resultModalRef.current && resultModalRef.current.show();
		}, 500);
	};
	// Hooks
	useEffect(() => {
		fingerprint.getData().then(({visitorFound, incognito}) => {
			setBrowserIncognitoState(incognito);
		}).catch(() => {
			setBrowserIncognitoState(false);
		});
		const prizeData = getPrizeStorage(props.cookieKey);
		if (prizeData && isMobile) {
			prizeRef.current = JSON.parse(prizeData);
			setPrize(JSON.parse(prizeData));
			resultModalRef.current && resultModalRef.current.show();
		}
	}, []);
	// Render
	return (
		<>
			<ResultModal
				type={prize.type}
				ref={resultModalRef}
				enableBackgroundClose={false}
				prizeLabel={prize.prizeLabel}
				prizeContent={prize.prizeContent} />
			<div className={styles.Home}>
				{isBrowserIncognito === null ? (
					<p className={clsx(styles.Home__disableDesktopLabel, props.disableTextClassName)}>Loading...</p>
				) : isBrowserIncognito ? (
					<>
						<p className={clsx(styles.Home__disableDesktopLabel, props.disableTextClassName)}>
							{'Don\'t open in incognito mode.'}
						</p>
						<p className={clsx(styles.Home__disableDesktopLabel, props.disableTextClassName)}>
							Copy the link to this page and open it in a regular mode (non-incognito) browser.
						</p>
						<Button
							size="large"
							label="Copy URL"
							className={styles.Home__disableButton}
							onClick={() => navigator.clipboard.writeText(window.location.href)} />
					</>
				) : isMobile ? (
					<>
						<div className={styles.Home__header}>
							<div className={styles.Home__header__center}>
								<img
									alt="Hangry-Icon"
									src={SVGHangryIcon} />
							</div>
						</div>
						<div className={styles.Home__body}>
							<img
								alt="logo"
								src={props.imageLogo}
								className={clsx(styles.Home__body__gameLogo, props.imageLogoClassName)} />
							<PieWheel
								data={props.data}
								ref={pieWheelRef}
								oddColor={props.oddColor}
								evenColor={props.evenColor}
								pointerSvg={props.pointerSvg}
								onComplete={onCompleteSpinner}
								wheelClassName={props.wheelClassName}
								className={styles.Home__body__pieWheel}
								onStart={() => setButtonHidden(true)}>
								<Button
									size="medium"
									scaled={true}
									label="START"
									color="secondary"
									onClick={onClickStartButton}
									className={clsx(
										styles.Home__body__startButton,
										{[styles['Home__body__startButton--hidden']]: startButtonHidden},
										props.buttonClassName,
									)} />
							</PieWheel>
							<p className={clsx(styles.Home__body__footer, props.footerClassName)}>By Hangry Indonesia</p>
						</div>
					</>
				) : (
					<p className={clsx(styles.Home__disableDesktopLabel, props.disableTextClassName)}>Unable to Load</p>
				)}
			</div>
		</>
	);
};

BaseComponent.defaultProps = {
	data: [],
	cookieKey: 'prize',
	imageLogo: SVGLogo,
	onClickStart: () => null,
};

BaseComponent.propTypes = {
	imageLogo: PropTypes.any,
	pointerSvg: PropTypes.any,
	oddColor: PropTypes.string,
	evenColor: PropTypes.string,
	cookieKey: PropTypes.string,
	onClickStart: PropTypes.func,
	data: PropTypes.array.isRequired,
	wheelClassName: PropTypes.string,
	buttonClassName: PropTypes.string,
	footerClassName: PropTypes.string,
	imageLogoClassName: PropTypes.string,
	disableTextClassName: PropTypes.string,
};

export default BaseComponent;
